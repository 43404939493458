<template>
  <div v-if="!loading">
    <div class="container wave-container dark-mode p-5">

      <!--<pre>{{ out }}</pre>-->

      <div class="row mb-4">
        <div class="col">
          <div>Res: <strong>{{ roundValue(freqResolution) }}Hz</strong></div>
          <div>Period: <strong>{{ roundValue(1 / frequency) }}s</strong></div>
          <div>{{ roundValue(sampleFreq / frequency) }} samples</div>
          <div>{{ roundValue(fftSize / (sampleFreq / frequency)) }} periods / fftSize</div>
          <div>{{ roundValue(inputLength * sampleFreq / fftSize) }} ffts / input</div>
        </div>
        <div class="col">
          <label class="btn" for="uploadFile">
            <font-awesome-icon icon="upload"></font-awesome-icon>
            Upload file
          </label>
          <input
            id="uploadFile"
            class="inputfile"
            type="file"
            @change="handleUpload"
          >
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label>fftSize</label>
          <select
            v-model="fftSize"
            class="form-control"
            @change="updateSyncDraw"
          >
            <option
              v-for="fftOption in fftSizeOptions"
              :value="fftOption"
            >
              {{ fftOption }}
            </option>
          </select>
        </div>
        <div class="col">
          <label>Fs</label>
          <input
            class="form-control"
            type="number"
            v-model="sampleFreq"
            min="1"
            max="44100"
            @input="updateSyncDraw"
          >
        </div>
        <div class="col">
          <label>Frequency</label>
          <input
            class="form-control"
            type="number"
            v-model="frequency"
            min="50"
            max="22050" step="50"
            @input="updateSyncDraw"
          >
        </div>
        <div class="col">
          <label>Input Length</label>
          <input
            class="form-control"
            type="number"
            v-model="inputLength"
            step="0.01"
            @input="updateSyncDraw"
          >
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <label>Waveform</label>
          <select
            v-model="waveform"
            class="form-control"
            @change="updateSyncDraw"
          >
            <option
            v-for="w in waveformOptions" :value="w"
            >
              {{ w }}
            </option>
          </select>
        </div>
        <div class="col">
          <label>Freq Scale</label>
          <select
            v-model="freqScaleMode"
            class="form-control"
            @change="updateSyncDraw"
          >
            <option
            v-for="fsm in logLinOptions" :value="fsm"
            >
              {{ fsm }}
            </option>
          </select>
        </div>
        <div class="col">
          <label>Amp Scale</label>
          <select
            v-model="ampScaleMode"
            class="form-control"
            @change="updateSyncDraw"
          >
            <option
            v-for="fsm in logLinOptions" :value="fsm"
            >
              {{ fsm }}
            </option>
          </select>
        </div>
        <!--<div class="col">
          <label>Log Base</label>
          <input
            class="form-control"
            type="number"
            v-model="logBase"
            @input="updateSyncDraw"
          >
        </div>-->
        <div class="col">
          <label>Window Function</label>
          <select
            v-model="windowFn"
            class="form-control"
            @change="updateSyncDraw"
          >
            <option
            v-for="w in windowOptions" :value="w"
            >
              {{ w }}
            </option>
          </select>
        </div>
      </div>

      <div id="spectrum" style="padding: 0 20px; width: 1064px;"></div>
      <div style="padding: 0; width: 1064px;">
        <svg id="spectrumAxis"></svg>
      </div>
      <div>
        <svg id="inputWave"></svg>
      </div>

      <div class="d-flex align-items-center">

        <div class="form-check mx-4">
          <input class="form-check-input" type="checkbox" id="checkboxShowWave" v-model="showWave" @change="updateSyncDraw">
          <label class="form-check-label" for="checkboxShowWave">
            Wave
          </label>
        </div>

        <div class="form-check mx-4">
          <input class="form-check-input" type="checkbox" id="checkboxShowWindowFn" v-model="showWindowFn" @change="updateSyncDraw">
          <label class="form-check-label" for="checkboxShowWindowFn">
            Window Function
          </label>
        </div>

        <div class="form-check mx-4">
          <input class="form-check-input" type="checkbox" id="checkboxShowAppliedWindowFn" v-model="showAppliedWindowFn" @change="updateSyncDraw">
          <label class="form-check-label" for="checkboxShowAppliedWindowFn">
            Applied Window Function
          </label>
        </div>

        <div class="flex-grow-1">
          <label>Zoom</label>
          <input v-model="zoomLevel" type="range" min="1" max="500" step="1" @change="draw" style="width: 100%;">
        </div>

      </div>

      <div>
        <svg id="wave"></svg>
        <table style="table-layout: fixed;">
          <tbody>
            <tr>
              <td v-for="freqFader in freqFaders" style="width: 80px; text-align: center;">
                <div>
                  <!-- <label >Harmonic 1</label><br/> -->
                  <div style="height: 100px">
                    <input type="range" min="0" :max="freqFader.max" v-model="freqFader.amp" :style="freqFader.style" step="1" style="width: 40px" orient="vertical">
                  </div>
                  <input type="range" min="0" max="360" v-model="freqFader.phase" step="1" style="width: 40px" orient="vertical">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>

import FFT from 'fft.js'
// import * as d3 from 'd3'
import { max } from 'lodash'

import windowFns from '../util/window-functions'

export default {
  components: { },
  data() {
    return {
      loading: true,
      input: null,
      amplitudes: [],
      svgObjects: {},
      waveForm: null,
      inputWaveForm: null,
      freqFaders: [],
      waveFormData: null,
      yRangeFreq: null,
      outData: [],
      averageOutData: null,
      // sampleFreq: 44100,
      sampleFreq: 44100,
      freqResolution: 0,
      fftSize: 32,
      fftSizeOptions: [8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768],
      frequency: 1000,
      waveformOptions: ['saw', 'square', 'triangle', 'sin', 'upload'],
      waveform: 'sin',
      logLinOptions: ['log', 'linear'],
      freqScaleMode: 'log',
      ampScaleMode: 'log',
      logBase: 10,
      windowOptions: ['none', 'blackman', 'hann', 'nuttall', 'blackman-nuttall', 'blackman-harris', 'tukey'],
      windowFn: 'blackman',
      inputLength: 0.5,
      currentWindowFnData: [],
      showWave: true,
      showWindowFn: true,
      showAppliedWindowFn: true,
      uploadBuffer: null,
      zoomLevel: 1,
    }
  },
  computed: {},
  created() {
    this.syncLocalStorage(true)
    this.update()
  },
  mounted() {
    this.draw()

  },
  methods: {
    handleUpload(e) {
      console.log('handleUpload', e)
      let file = e.target.files[0],
          reader = new FileReader();

      console.log(file)

      reader.readAsArrayBuffer(file)

      reader.onload = (e) => {
        console.log('hmm', e)

        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const aCtx = new AudioContext()

        aCtx.decodeAudioData(e.target.result).then((buffer) => {
          this.uploadBuffer = buffer.getChannelData(0)
          console.log(buffer)
          this.waveform = 'upload'
          this.update()
          this.syncLocalStorage()
          this.draw()
        })
      }
    },
    updateSyncDraw() {
      console.log('updateSyncDraw')
      this.update()
      this.syncLocalStorage()
      this.draw()
    },
    roundValue(value) {
      return value.toString().substr(0, 4)
    },
    syncLocalStorage(load) {
      const storedAttrs = ['fftSize', 'frequency', 'waveform', 'freqScaleMode', 'ampScaleMode', 'sampleFreq', 'logBase', 'windowFn', 'inputLength']
      if (!load) {
        storedAttrs.forEach((attr) => {
          window.localStorage.setItem(attr, this[attr])
        })
      } else {
        storedAttrs.forEach((attr) => {
          const item = window.localStorage.getItem(attr)
          if (item) {
            this[attr] = item
          }
        })
      }
    },
    update() {
      this.loading = true

      const fftSize = parseInt(this.fftSize)
      const fft = new FFT(fftSize)

      this.freqResolution = this.sampleFreq / fftSize

      const inputSampleLength = Math.floor(this.inputLength * this.sampleFreq)
      const remainingLength = Math.ceil(inputSampleLength % fftSize)
      let remainingLengthToMatchFftLength = 0
      if (remainingLength > 0) {
        remainingLengthToMatchFftLength = fftSize - remainingLength
      }

      console.log('array length', inputSampleLength, remainingLengthToMatchFftLength, inputSampleLength + remainingLengthToMatchFftLength)

      const input = new Array(inputSampleLength + remainingLengthToMatchFftLength)

      const frequency = this.frequency
      const period = 1 / frequency
      const angularFrequency = Math.PI * 2 * frequency
      const phase = 0

      const numHarmonics = 1

      input.fill(0)

      for (let i = 1; i <= inputSampleLength; i++) {
        const t = ((i - 1) / this.sampleFreq)

        if (this.waveform === 'sin') {
          let mag = 0
          for (let h = 1; h <= numHarmonics; h++) {
            mag += (1 / h) * Math.sin(angularFrequency * h * t)
          }
          mag *= (0.5 - (1 / Math.PI)) + 0.5
          input[i - 1] = mag

        } else if (this.waveform === 'saw') {
          input[i - 1] = (2 * ((t / period) - Math.floor(0.5 + (t / period)))) * 1

        } else if (this.waveform === 'square') {
          input[i - 1] = Math.pow(-1, Math.floor(2 * frequency * t)) * 1

        } else if (this.waveform === 'triangle') {
          const a = 1 // amplitude
          input[i - 1] = (2 * a / Math.PI) * Math.asin(Math.sin( ( 2 * Math.PI / period ) * t ))

        } else if (this.waveform === 'upload' && this.uploadBuffer) {
          input[i - 1] = this.uploadBuffer[i - 1]

        } else {
          input[i  - 1] = 0

        }

      }

      this.input = input

      const fftPerInput = Math.ceil(input.length / fftSize)
      const outData = new Array(fftPerInput)
      // console.log('fftPerInput', fftPerInput)
      for (let f = 0; f < fftPerInput; f++) {
        const fftInput = new Array(fftSize)

        // window function

        const offset = f * fftSize
        const currentInput = input.slice(offset, offset + fftSize)

        if (typeof windowFns[this.windowFn] === 'function') {
          windowFns[this.windowFn](currentInput, fftInput)

          if (f === 0) {
            const windowFnData = new Array(fftSize)
            const oneArray = new Array(fftSize)
            oneArray.fill(1)
            windowFns[this.windowFn](oneArray, windowFnData)
            this.currentWindowFnData = windowFnData
          }
        }

        // FFT

        const out = fft.createComplexArray()
        fft.realTransform(out, fftInput)

        const currentOutData = {
          fftNum: f,
          mags: new Array(out.length / 2),
          phases: new Array(out.length / 2),
        }

        const scalingFactor = fftSize / 2

        for (let i = 0; i < fftSize; i++) {
          const re = out[i] / scalingFactor
          const im = out[i + 1] / scalingFactor

          let mag = Math.sqrt(
            Math.pow(re, 2) + Math.pow(im, 2)
          )

          if (mag < 1e-15) {
            mag = 0
          }

          // average over all ffts
          currentOutData.mags[i] = mag
          currentOutData.phases[i] = Math.atan2(im, re)
        }

        outData[f] = currentOutData

      }

      // calc average
      const averageOutData = {
        mags: new Array(fftSize),
        phases: new Array(fftSize),
      }

      for (let i = 0; i < fftSize; i++) {
        let mag = 0
        let phase = 0
        for (let d = 0; d < outData.length; d++) {
          mag += outData[d].mags[i]
          phase += outData[d].phases[i]
        }
        averageOutData.mags[i] = mag / outData.length
        averageOutData.phases[i] = phase / outData.length
      }

      // this.out
      this.averageOutData = averageOutData

      this.loading = false

    },
    draw() {
      // this.setupWave()
      this.setupInputWave()
      this.drawFreq()

      //setTimeout(() => {
        // d3.timer(this.draw, 100)
      //}, 1000)
      // this.draw()

    },
    drawUpdate() {
      /* for (let i = 0; i < this.out.length / 2; i++) {
        const amp = this.freqFaders[i].amp / 100
        this.svgObjects.frequencyVectors[i]
          .attr("y1", this.yRangeFreq(0))
          .attr("y2", this.yRangeFreq(amp))

        this.svgObjects.freqSamples[i]
          .attr("y", this.yRangeFreq(amp));
      } */

      this.svgObjects.path.attr('d', this.waveForm(this.waveFormData));

    },
    setupInputWave() {
      var canvasWidth = 1000;
      var canvasHeight = 450;
      const svgElement = '#inputWave'

      var MARGINS =
        {
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        };

      var plotWidth = canvasWidth - MARGINS.left - MARGINS.right;
      var plotHeight = canvasHeight - MARGINS.top - MARGINS.bottom;

      document.querySelector(svgElement).style.width = canvasWidth + 'px'
      document.querySelector(svgElement).style.height = canvasHeight + 'px'
      document.querySelector(svgElement).innerHTML = ''
      var vis = d3.select(svgElement);

      const inputLength = this.input.length
      const outLength = this.averageOutData.mags.length
      const fftSize = parseInt(this.fftSize)

      var xRange = d3.scale.linear().range([MARGINS.left, plotWidth - MARGINS.right]);
      var yRange = d3.scale.linear().range([plotHeight -  MARGINS.bottom, MARGINS.top]);

      xRange.domain([0, this.inputLength]);
      yRange.domain([-2, 2]);

      var xAxis = d3.svg.axis()
        .scale(xRange)
        .tickSize(5)
        .ticks(4)
        .tickSubdivide(true);

      var yAxis = d3.svg.axis()
        .scale(yRange)
        .tickSize(5)
        .ticks(3)
        .orient('left')
        .tickSubdivide(true);

      vis.append('svg:g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + (plotHeight / 2) + ')')
        .style('opacity', 0.25)
        .call(xAxis);

      vis.append('svg:g')
        .attr('class', 'y axis')
        .attr('transform', 'translate(' + MARGINS.left + ',0)')
        .style('opacity', 0.25)
        .call(yAxis);

      const xRangePlot = d3.scale.linear().range([MARGINS.left, plotWidth - MARGINS.right])
      xRangePlot.domain([0, 1 / this.zoomLevel])

      const inputSamples = this.input
      let waveformSamples
      let downSampleFactor

      // downsample
      const samplesPerPixel = 2
      if (inputLength > samplesPerPixel * plotWidth) {
        downSampleFactor = Math.floor(inputLength / (plotWidth * samplesPerPixel))
        waveformSamples = inputSamples.filter((d, i) => i % downSampleFactor === 0)

      } else {
        downSampleFactor = 1
        waveformSamples = inputSamples

      }

      if (this.showWave) {
        const inputWaveFormInterpolated = d3.svg.line()
          .x((d, i) => xRangePlot(i * downSampleFactor / inputLength))
          .y((d, i) => yRange(d))

        const inputPathInterpolated = vis.append('svg:path')
          .attr("stroke-width", 2.0)
          .attr("stroke", "#8df")
          .attr("fill", "rgba(136, 221, 255, 0.5)")
          .style("opacity", 1);

        inputPathInterpolated.attr('d', inputWaveFormInterpolated([0, ...waveformSamples]));
      }

      let fftIndex

      // window function path

      if (this.showWindowFn) {
        const windowFnLine = d3.svg.line()
          .x((d, i) => xRangePlot((i * downSampleFactor / inputLength)))
          .y((d, i) => yRange(d))

        const windowFnPath = vis.append('svg:path')
          .attr('stroke-width', 1.0)
          .attr('stroke', '#fff')
          .attr('fill', '#8df')
          .style('opacity', 0.1)

        const windowFnInput = new Array(waveformSamples.length)

        for (let i = 0; i < inputLength; i++) {
          if (i % downSampleFactor === 0) {
            fftIndex = i % fftSize
            windowFnInput[i / downSampleFactor] = this.currentWindowFnData[fftIndex]
          }
        }

        windowFnPath.attr('d', windowFnLine(windowFnInput))
      }

      // window function * input path

      if (this.showAppliedWindowFn) {
        const appliedWindowFnLine = d3.svg.line()
          .x((d, i) => xRangePlot((i * downSampleFactor / inputLength)))
          .y((d, i) => yRange(d))

        const appliedWindowFnPath = vis.append('svg:path')
          .attr('stroke-width', 1.0)
          .attr('stroke', '#fff')
          .attr('fill', '#f68')
          .style('opacity', 0.5)

        const appliedWindowFnInput = new Array(waveformSamples.length)

        for (let i = 0; i < inputLength; i++) {
          if (i % downSampleFactor === 0) {
            fftIndex = i % fftSize
            appliedWindowFnInput[i / downSampleFactor] = this.currentWindowFnData[fftIndex] * this.input[i]
          }
        }

        appliedWindowFnPath.attr('d', appliedWindowFnLine(appliedWindowFnInput))
      }

    },
    setupWave() {
      var canvasWidth = 1000;
      var canvasHeight = 400;
      const svgElement = '#wave'

      var MARGINS =
        {
          top: 0,
          right: 10,
          bottom: 0,
          left: 10
        };

      var plotWidth = canvasWidth - MARGINS.left - MARGINS.right;
      var plotHeight = canvasHeight - MARGINS.top - MARGINS.bottom;

      document.querySelector(svgElement).style.width = canvasWidth + 'px'
      document.querySelector(svgElement).style.height = canvasHeight + 'px'
      var vis = d3.select(svgElement);

      const inputLength = this.input.length
      const outLength = this.outData.mags.length

      var xRange = d3.scale.linear().range([MARGINS.left, plotWidth]);
      var yRange = d3.scale.linear().range([plotHeight, MARGINS.top]);

      xRange.domain([-1.25 * 2.0, 1.25 * 2.0]);
      yRange.domain([-1.25 * 3.0, 1.25 * 3.0]);

      var xAxis = d3.svg.axis()
        .scale(xRange)
        .tickSize(5)
        .ticks(4)
        .tickSubdivide(true);

      var yAxis = d3.svg.axis()
        .scale(yRange)
        .tickSize(5)
        .ticks(3)
        .orient('left')
        .tickSubdivide(true);

      const faderLength = outLength < 16 ? outLength : 16

      for (var i = 0; i < faderLength; i++)
      {
        const max = Math.floor(this.outData.mags[i+1] * 100)
        const phase = Math.floor(this.outData.phases[i+1] * 180 / Math.PI)
        this.freqFaders.push({ amp: max, phase, max, style: { height: max + 'px' } })
      }

      vis.append('svg:g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + (plotHeight / 2) + ')')
        .style('opacity', 0.25)
        .call(xAxis);

      vis.append('svg:g')
        .attr('class', 'y axis')
        .attr('transform', 'translate(' + plotWidth / 2 + ',0)')
        .style('opacity', 0.25)
        .call(yAxis);

      const xRangePlot = d3.scale.linear().range([MARGINS.left, plotWidth])
      xRangePlot.domain([0, 2 * Math.PI])

      this.waveFormData = d3.range(0, 2 * Math.PI, 0.05);

      const self = this
      this.waveForm = d3.svg.line()
        .x(function(d, i) {
          // return xRangePlot((i / inputLength) * 2 * Math.PI)
          return xRangePlot(d)
        })
        .y(function(d, i) {
          let amp = 0
          let bin = 0
          self.freqFaders.forEach((ff) => {
            amp += Math.sin((d * bin) + (ff.phase / 180 * Math.PI)) * (ff.amp / 100)
            bin++
          })
          return yRange(amp)
        })

      this.svgObjects.path = vis.append('svg:path')
        .attr("stroke-width", 2.0)
        .attr("stroke", "#7df")
        .attr("fill", "none")
        .style("opacity", 0.75);

    },
    drawFreq() {
      const width = 1024
      const height = 300
      // const padding = 0
      const axisPadding = 20

      // const freqData = this.outData.mags.map((d, i) => ({ bin: i, value: d }))
      // const freqPhase = this.outData.phases.map((d, i) => ({ bin: i, value: d }))

      const spectrumEl = document.querySelector('#spectrum')
      spectrumEl.style.width = width + (2 * axisPadding) + 'px'
      spectrumEl.style.height = height + 'px'
      const existingCanvas = document.querySelector('#spectrum > canvas')
      const canvas = existingCanvas || document.createElement('canvas')
      const axisSvg = document.querySelector('#spectrumAxis')

      if (!existingCanvas) {
        canvas.width = width
        canvas.height = height

        spectrumEl.appendChild(canvas)
        // spectrumEl.appendChild(axisSvg)
        axisSvg.style.width = (width + (2 * axisPadding)) + 'px'
        axisSvg.style.height = '50px'

      } else {
        axisSvg.innerHTML = ''

      }

      // axis
      var axisSvgVis = d3.select('#spectrumAxis');

      if (this.freqScaleMode === 'linear') {
        var xRange = d3.scale.linear().range([0 + axisPadding, width + axisPadding])
      } else if (this.freqScaleMode === 'log') {
        var xRange = d3.scale.log().range([0 + axisPadding, width + axisPadding]).base(this.logBase)
        // xRange.tickFormat(Infinity, '')
      }

      // console.log('this.ampScaleMode',this.ampScaleMode)

      if (this.ampScaleMode === 'linear') {
        var ampRange = d3.scale.linear().range([0, 1])
        ampRange.domain([1, 2])
      } else if (this.ampScaleMode === 'log') {
        var ampRange = d3.scale.log().range([0, 1])
        ampRange.domain([1, 2])
      }
      var ampAxis = d3.svg.axis().scale(ampRange)

      xRange.domain([1, this.sampleFreq/2])

      var xAxis = d3.svg.axis()
        .scale(xRange)
        .ticks(5)
        /* .tickSize(5)
        .ticks(10)
        .tickSubdivide(true); */

      axisSvgVis.append('svg:g')
        .attr('class', 'x axis')
        .style('opacity', 0.75)
        .call(xAxis);

      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      const data = this.averageOutData.mags
      const maxValue = max(data)
      // console.log('maxValue', maxValue)
      const pixelPerBin = width >= data.length ? (width / data.length) : 1
      // console.log('maxValue', maxValue, 'ppb', pixelPerBin, 'dl', data.length)

      let scale = 1

      if (width < data.length) {
        scale = width / data.length
        //ctx.scale(1/scale, 1)
      }

      // console.log('logBase', this.logBase)

      const logScale = (value) => {
        // return (width / (Math.log(data.length * pixelPerBin) / Math.log(this.logBase))) * (Math.log(value) / Math.log(this.logBase))
        // return (Math.log10(value) / Math.log10(width)) * width
        // return xRange((value / pixelPerBin) * (this.sampleFreq / 2) / data.length) - axisPadding
        return xRange((value / pixelPerBin) * (this.sampleFreq / 2)) - axisPadding
      }

      for (let bin = 0; bin < data.length-1; bin++) {
        const y = 0
        const rectHeight = height

        let x = (pixelPerBin * bin)
        let rectWidth = pixelPerBin

        let rectXStart = x - (rectWidth / 2)
        let rectXEnd = x + (rectWidth / 2)

        if (x === 0) {
          rectXStart = 1
        } else {
          rectXStart = logScale(rectXStart / data.length)
        }
        rectXEnd = logScale(rectXEnd / data.length)
        rectWidth = rectXEnd - rectXStart


        // console.log(data[bin], ampRange(data[bin]))
        const value = ampRange(data[bin] + 1)
        // const value = 1
        const saturation = (100 * (value / maxValue))
        // const lightness = 60
        const lightness = (50 * (value / maxValue))
        const binColor = 80 - saturation
        // const alpha = Math.min(value * 5, 1)
        const alpha = 1
        const currentColor = 'hsla(' + binColor + ',' + saturation + '%,' + lightness + '%, ' + alpha + ')'

        ctx.fillStyle = currentColor

        ctx.fillRect(rectXStart, y, rectWidth, rectHeight)
      }

      if (scale !== 1) {
        // ctx.scale( scale, 1)
      }

    }
  }
}

</script>

<style>
  .axis {
    font: 10pt;
    stroke: rgba(255, 255, 255, 0.8);
  }

  .axis path, .axis line {
    fill: none;
    stroke: rgba(255, 255, 255, 0.8);
    shape-rendering: crispEdges;
  }

  input[type=range][orient=vertical]
  {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    /* width: 8px;
    height: 175px;
    padding: 0 5px; */
  }

  .wave-container {
    background: rgba(0, 0, 0, 0.8);
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.8); */
  }
</style>
