export default {
    none(input, output) { input.forEach((v, i) => { output[i] = v }) },

    blackman(input, output) {
      const a_0 = 7938/18608
      const a_1 = 9240/18608
      const a_2 = 1430/18608
      for (let i = 0; i < input.length; i++) {
        let twoPiNOverN = 2 * Math.PI * (i + 1) / input.length
        output[i] = input[i] * (a_0 - (a_1 * Math.cos(twoPiNOverN)) + (a_2 * Math.cos(2 * twoPiNOverN)))
      }
    },
    hann(input, output) {
      for (let i = 0; i < input.length; i++) {
        output[i] = input[i] * (Math.pow(Math.sin(Math.PI * i / input.length), 2))
      }
    },
    nuttall(input, output) {
      const a_0 = 0.355768
      const a_1 = 0.487396
      const a_2 = 0.144232
      const a_3 = 0.012604
      for (let i = 0; i < input.length; i++) {
        let twoPiNOverN = 2 * Math.PI * (i + 1) / input.length
        output[i] = input[i] * (a_0 - (a_1 * Math.cos(twoPiNOverN)) + (a_2 * Math.cos(2 * twoPiNOverN)) - (a_2 * Math.cos(3 * twoPiNOverN)))
      }
    },
    'blackman-nuttal': (input, output) => {
      const a_0 = 0.3635819
      const a_1 = 0.4891775
      const a_2 = 0.1365995
      const a_3 = 0.0106411
      for (let i = 0; i < input.length; i++) {
        let twoPiNOverN = 2 * Math.PI * (i + 1) / input.length
        output[i] = input[i] * (a_0 - (a_1 * Math.cos(twoPiNOverN)) + (a_2 * Math.cos(2 * twoPiNOverN)) - (a_2 * Math.cos(3 * twoPiNOverN)))
      }
    },
    'blackman-harris': (input, output) => {
      const a_0 = 0.35875
      const a_1 = 0.48829
      const a_2 = 0.14128
      const a_3 = 0.01168
      for (let i = 0; i < input.length; i++) {
        let twoPiNOverN = 2 * Math.PI * ((i + 1) / input.length)
        output[i] = input[i] * (a_0 - (a_1 * Math.cos(twoPiNOverN)) + (a_2 * Math.cos(2 * twoPiNOverN)) - (a_2 * Math.cos(3 * twoPiNOverN)))
      }
    },
    tukey(input, output) {
      const alpha = 0.5
      for (let i = 0; i < input.length; i++) {
        if (i < (alpha * input.length / 2)) {
          const w = 0.5 * (1 - Math.cos())
          output[i] = input[i] * (w)
          input[input.length - i - 1] *= w
        } else if (i >= (alpha * input.length / 2) && i <= input.length / 2) {
          output[i] = input[i] * (1)
          input[input.length - i - 1] *= 1
        }
      }
    },
}
